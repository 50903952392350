import ApiClient from '@/api/ApiClient';
import { ClassDiaryResponse } from 'chaild-api/lib';

export default class ApiClassDiary {

  public static async listClassDiary(input: {
    classId?: number;
    nurseryId: number;
    from?: string;
    to?: string;
    limit: number;
    skip: number;
  }) {
    const response = await ApiClient.get(`/classes/diary/search`, input);
    if (response && response.diaries) {
      return response;
    }
    return null;
  }
  
  public static async getClassDiary(input: { classId: number; date: string }) {
    try {
      const response = await ApiClient.get(`/classes/${input.classId}/diary?date=${input.date}`, null);
      return response;
    } catch (e) {
      return null;
    }
  }

  public static async findClassDiary(classId: number, classDiaryId: number): Promise<ClassDiaryResponse | null> {
    const response = await ApiClient.get(`/classes/diary/${classDiaryId}`, null);
    if (response) {
      return response as ClassDiaryResponse;
    }
    return null;
  }

  public static async createClassDiary(input: {
    classId: number;
    date: string;
    aim: string;
    activityAm: string;
    activityPm: string;
    assessment: string;
    support: string;
    appliedBy?: number;
    status: 'draft' | 'applied';
  }) {
    const response = await ApiClient.post(`/classes/${input.classId}/diary`, {
      date: input.date,
      aim: input.aim,
      activityAm: input.activityAm,
      activityPm: input.activityPm,
      assessment: input.assessment,
      support: input.support,
      appliedBy: input.appliedBy,
      status: input.status,
    });
    return response;
  }

  public static async exportExcel(input: {
    classId: number;
  }) {
    const response = await ApiClient.get(`/classes/${input.classId}/diary/export-excel`, null);
    return response;
  }

  public static async updateClassDiary(input: {
    classDiaryId: number;
    classId: number;
    aim: string;
    activityAm: string;
    activityPm: string;
    assessment: string;
    support: string;
    appliedBy?: number;
    status: 'draft' | 'applied';
  }) {
    const response = await ApiClient.put(`/classes/diary/${input.classDiaryId}`, {
      aim: input.aim,
      activityAm: input.activityAm,
      activityPm: input.activityPm,
      assessment: input.assessment,
      support: input.support,
      appliedBy: input.appliedBy,
      status: input.status,
    });
    if (response) {
      return response as ClassDiaryResponse;
    }
    return null;
  }

  public static async createClassDiaryApproval(input: {
    classId: number;
    classDiaryId: number;
    approvalStatus: 'approved' | 'rejected';
  }) {
    const response = await ApiClient.put(`/classes/diary/${input.classDiaryId}/approval`, {
      approvalStatus: input.approvalStatus
    });

    if (response) {
      return response as ClassDiaryResponse;
    }

    return null;
  }

  public static async listChildrenReport(input: {
    classId: number;
    date?: string;
  }) {
    const response = await ApiClient.get(`/classes/${input.classId}/report`, input);
    return response;
  }

  public static async listChildrenMeal(input: {
    classId: number;
    date?: string;
  }) {
    const response = await ApiClient.get(`/classes/${input.classId}/meal`, input);
    return response;
  }

  public static async deleteClassDiary(input: {
    classDiaryId: string;
  }) {
    const response = await ApiClient.delete(`/classes/diary/${input.classDiaryId}`, null);
    return response;
  }

  // クラス日誌検索結果のダウンロード
  public static async downloadClassDiaries(input: {
    nurseryId: number;
    classIds: number[];
    from?: string; // YYYY-MM-DD
    to?: string;
  }) {
    return await ApiClient.post('/classes/diary/search/download', input);
  }

}