import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiShift, { ShiftTimeTableRequest } from '@/api/ApiShift'
import { WorkDescriptionSettingResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';

export interface WorkDescriptionState {
  workDescriptions: WorkDescriptionSettingResponse[];
}


@Module({ dynamic: true, store, name: 'work-description', namespaced: true })
class WorkDescriptionModule extends VuexModule implements WorkDescriptionState {
  public workDescriptions: WorkDescriptionSettingResponse[] = [];

  @Action
  public async listWorkDescriptions() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiShift.listWorkDescriptions(nurseryId);

      if (response) {
        this.setWorkDescriptions(response)
      }
    }
  }

  @Action
  public async createWorkDescription({
    name,
    abbreviation,
    color,
    isChildcare
  }: {
    name: string;
    abbreviation: string;
    color: string;
    isChildcare: boolean;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiShift.createWorkDescription({
        nurseryId,
        name,
        abbreviation,
        color,
        isChildcare,
      })

      if (response) {
        this.addWorkDescriptions([response])
      }
    }
  }

  @Action
  public async updateWorkDescription({
    workDescriptionId,
    name,
    abbreviation,
    color,
    isChildcare
  }: {
    workDescriptionId: number;
    name: string;
    abbreviation: string;
    color: string | null;
    isChildcare: boolean;
  }) {
    const response = await ApiShift.updateWorkDescription({
      workDescriptionId,
      name,
      abbreviation,
      color,
      isChildcare,
    })

    if (response) {
      this.updateWorkDesciription(response)
    }
  }

  @Action
  public async deleteWorkDescription(workDescriptionId: number) {
    const response = await ApiShift.deleteWorkDescription(workDescriptionId)
    if (
      response
      && response.message
      && response.message === 'ok'
    ) {
      this.removeWorkDescription(workDescriptionId)
    }
  }


  @Mutation
  public setWorkDescriptions(works: WorkDescriptionSettingResponse[]) {
    this.workDescriptions = works
  }

  @Mutation
  public addWorkDescriptions(works: WorkDescriptionSettingResponse[]) {
    this.workDescriptions = this.workDescriptions.concat(works)
  }

  @Mutation
  public updateWorkDesciription(work: WorkDescriptionSettingResponse) {
    this.workDescriptions = this.workDescriptions.map((w) => {
      if (w.workDescriptionId === work.workDescriptionId) {
        return work
      }
      return w
    })
  }

  @Mutation
  public removeWorkDescription(workDescriptionId: number) {
    this.workDescriptions = this.workDescriptions.filter((w) => w.workDescriptionId !== workDescriptionId)
  }
}

export const workDescriptionModule = getModule(WorkDescriptionModule);