
import Vue from "vue";
import dayjs from "dayjs";

import { classDiaryModle } from "@/store/dataModules/classDiaryModue";
import { classModule } from "@/store/dataModules/classModule";

import { ClassDiaryResponse, NurseryClassResponse } from "chaild-api/lib";

import LocalDataService from "@/service/LocalDataService";
import ApiClassDiary from "@/api/ApiClassDiary";

interface DataType {
  isSearching: boolean;
  page: number;
}

export default Vue.extend({
  computed: {
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    diaries(): ClassDiaryResponse[] {
      return classDiaryModle.diaries;
    },
    sorted(): ClassDiaryResponse[] {
      return [...this.diaries].sort((d1, d2) => {
        const date1 = dayjs(d1.date);
        const date2 = dayjs(d2.date);
        return date2.diff(date1, "day");
      });
    },
    classId: {
      get() {
        return classDiaryModle.classId;
      },
      set(value: number | null) {
        classDiaryModle.setClassId(value);
      },
    },
    from: {
      get() {
        return classDiaryModle.from;
      },
      set(value: string) {
        classDiaryModle.setFrom(value);
      },
    },
    to: {
      get() {
        return classDiaryModle.to;
      },
      set(value: string) {
        classDiaryModle.setTo(value);
      },
    },
    limit: function (): number {
      return classDiaryModle.limit;
    },
    totalItemsCount: function (): number {
      return classDiaryModle.totalItemsCount;
    },
  },

  data: (): DataType => {
    return {
      isSearching: false,
      page: 1,
    };
  },

  watch: {
    page: {
      handler(val) {
        classDiaryModle.setSkip(val - 1);
        this.search();
      },
    },
  },

  methods: {
    async search() {
      this.isSearching = true;
      await classDiaryModle.listDiary().catch(() => (this.isSearching = false));
      this.isSearching = false;
    },
    async deleteClassDiary(classDiaryId: string) {
      if (confirm("削除しますか？")) {
        this.isSearching = true;
        const response = await classDiaryModle.deleteClassDiary({
          classDiaryId,
        });
        if (response) {
          this.search();
        }
        this.isSearching = false;
      }
    },
    isToday(date: string) {
      return dayjs().format("YYYY-MM-DD") === date;
    },
    getClassName(classId: number) {
      const find = this.classes.find((c) => c.classId === classId);
      if (find) {
        return find.className;
      }
      return "不明なクラス";
    },
    async downloadDiaries() {
      this.isSearching = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        // request DL
        const classIds: number[] = [];
        if (classDiaryModle.classId) {
          classIds.push(classDiaryModle.classId);
        }
        const diaryResponse = await ApiClassDiary.downloadClassDiaries({
          nurseryId,
          classIds: classIds,
          from: classDiaryModle.from || undefined,
          to: classDiaryModle.to || undefined,
        }).catch(() => (this.isSearching = false));
        if (diaryResponse) {
          alert(
            "ダウンロードファイルを生成中です\n完了後に「ダウンロード一覧」画面からダウンロードすることができます"
          );
          this.$router.push({ path: "/download/list" });
        }
      }
    },
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await classModule.listClass({
        nurseryId: nurseryId,
      });
      await this.search();
    }
  },
});
